import React from "react"
import { Link } from "gatsby"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"

import morale from "../images/morale.jpg"
import coaching from "../images/coaching.png"
import support from "../images/morale-support.jpg"

const CoachingServices = () => (
  <Layout>
    <SEO
      title="One On One Coaching – Professional Coaching Services"
      description="We are proud to be able to provide professional coaching services! Take your understanding of yourself to the next level through one-on-one coaching with AGL."
    />
    <div className="page-headline">
      <div className="container ">
        <div className="section-heading text-center">
          <h1>
            <strong>One On One Coaching</strong>
          </h1>
        </div>
        <div className="my-5 text-center">
          <Link to="/#get-coaching">
            <span className="hero-btn2 align-items-center sched-modal">
              Get Coaching
            </span>
          </Link>
        </div>
      </div>
      <div className="headline-bg" />
    </div>
    <div className="page-content">
      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-3 mx-auto text-center">
            <LazyLoadImage
              className="engager-icon mr-3 img-fluid mb-5"
              src={coaching}
              alt="Coaching"
            />
          </div>
          <div className="col-md-8 mx-auto">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <h2 className="mb-0">
                    <strong>One on One Coaching</strong>{" "}
                  </h2>
                  <p className="">
                    The coaching industry has exploded over the past several
                    years as more people pursue positive development in their
                    personal lives and businesses, and it isn't difficult to
                    understand the reason behind the swarm. Professional
                    coaching services, specifically one on one coaching, can
                    provide answers to those seeking a higher understanding of
                    themselves and the way that they interact with the world
                    around them.
                  </p>
                  <p className="">
                    One on one coaching offers the flexibility of
                    personalization in that it can be tailored to elevate each
                    individual who engages with their coach. This means that
                    deep questions can be addressed, and complex goals can be
                    achieved through multiple productive sessions. If you're
                    eager and excited to exact change in your life and invest in
                    your own positive growth, one on one coaching is for you.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content coaching-bg">
      <div className="container">
        <div className="row justify-content-between pt-5 p-2  text-white">
          <div className="col-md-6 mx-auto">
            <h2 className="mb-0	text-white">
              <strong>
                Benefits of One on One Coaching & Professional Coaching Services
              </strong>{" "}
            </h2>
            {/* <ol className=" text-left text-white">
              <li className=" my-3">
                It’s about INVESTing. Invest in yourself. Invest in your
                leadership potential. Invest in your team and your organization.
                Things don’t grow without investment, and nothing invested is
                ever wasted.
              </li>
              <li className=" my-3">
                It’s about VESTing. The word “vest” means to confer or bestow
                authority. Sometimes, we are just looking for someone outside of
                ourselves to give us a little validation. To let us know that
                we’re on the right track, and give us an idea of what to do
                next.
              </li>
              <li className=" my-3">
                It’s about LEADERvesting. Not just investing some dollars in
                training. Not just receiving someone else’s validation. It’s
                about finding the leader in yourself, calling out your own
                authority and power, understanding your unique values and
                knowing how they show up in everything you do. It’s choosing to
                claim, confer and bestow your own authority for yourself and
                this leadership training program does all the above and more.
              </li>
            </ol> */}
            <ul className="checks-ul text-white">
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Refining and Achieving Goals.</strong>
                <br />
                Do you feel comfortable having difficult conversations with your
                team or do you dread having those “hard conversations?
              </li>
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Building Effective Teams.</strong>
                <br />
                Employers can gain insight into how best to manage the strengths
                of their team members and pair them strategically with others.
              </li>
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Evaluating Oneself.</strong>
                <br />
                Leaders can develop an understanding of their strengths and weak
                points, improving self-awareness in an effort to enhance
                performance.
              </li>
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Developing Emotional Intelligence.</strong>
                <br />
                Understand how emotions are triggered and learn how to channel
                motivation to control external reactions.
              </li>
            </ul>
          </div>
          <div className="col-md-6 mx-auto">
            <ul className="checks-ul text-white">
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Empathizing With Others.</strong>
                <br />
                Develop an understanding of how others handle their emotions and
                provide better support through thoughtful leadership.
              </li>
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Thinking Flexibly.</strong>
                <br />
                By studying the thoughts of others, leaders can enhance their
                own critical thinking skills and become better at evaluating and
                managing teams.
              </li>
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Relating to Others Successfully.</strong>
                <br />
                Enhance social and relationship-building skills to become a
                better communicator, negotiator, team player, and collaborator.
              </li>
              <li
                className="list-checks-white my-3 herop text-white"
                style={{ color: `#434343` }}
              >
                <strong>Leading.</strong>
                <br />
                Become a stronger leader by practicing problem-solving, offering
                encouragement, and instilling confidence in your team.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="page-content">
      {/*<div className="col-md-3 mx-auto">
						<LazyLoadImage
							className="engager-icon mr-3 img-fluid"
							src={support}
							alt="Engager Icon"
						/>
					</div>*/}

      <div className="container">
        <div className="row justify-content-between pt-5">
          <div className="col-md-6">
            <h2 className="mb-0 text-gold">
              <strong>
                Why Choose Coaching for Good for Professional Coaching Services?
              </strong>{" "}
            </h2>
{/*          
            <div className="mx-auto">
              <LazyLoadImage
                className="mr-3 mb-3 img-fluid"
                src={support}
                alt="Engager Icon"
              />
            </div> */}
          </div>
          <div className="col-md-6">
            <p className="">
            Ready to become a better leader? Contact us today to book a consultation with ICF certified coaches that can help you discover your confidence for leading a team towards success.

            </p>
            <div className="my-5">
          <Link to="/#get-coaching">
            <span className="hero-btn align-items-center sched-modal">
              Get Coaching
            </span>
          </Link>
        </div>
            
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default CoachingServices
